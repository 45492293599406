<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-title">
        <label-span label="收票组织：" />
        <organization-select
          :model="org"
          allLabel="请选择收票组织"
          :is-add-all="false"
          style="display: flex; height: 20px; align-items: center"
        />
        <span
          style="
            display: flex;
            margin-left: 30px;
            color: red;
            font-size: 14px;
            font-weight: 400;
          "
          >点击下方文本框获取焦点，扫描录入时请切换至英文输入法！</span
        >
      </div>
      <div class="select-content">
        <el-input
          ref="textarea"
          type="textarea"
          v-model="invoiceQrCode"
          placeholder="请点击获取焦点"
          @keyup.enter.native="handleAnalysisQrCode"
        />
      </div>
    </div>
    <div class="content-main">
      <div class="content-table">
        <div class="table-title">
          <div>票据列表</div>
          <el-button
            type="primary"
            :disabled="isDisabled"
            @click="handleInvoiceSubmit"
            >提交</el-button
          >
        </div>
        <div class="table-content">
          <el-table
            :data="tableData"
            stripe
            border
            v-loading="dataLoading"
            :header-cell-style="handleHeaderCellStyle"
            style="width: 100%"
          >
            <el-table-column type="index" label="序号" width="60" />
            <el-table-column
              prop="invoiceType"
              label="发票类型"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              prop="eInvoiceNo"
              label="数电票号"
              min-width="180"
            />
            <el-table-column
              prop="invoiceNo"
              label="发票号码"
              min-width="140"
            />
            <el-table-column prop="invoiceCode" label="发票代码" width="180" />
            <el-table-column prop="invoiceTime" label="开票日期" width="160" />
            <el-table-column prop="amount" label="未税金额" width="180" />
            <el-table-column prop="sumAmount" label="价税合计" width="180" />
            <el-table-column
              prop="verifyCode"
              label="校验码后六位"
              width="180"
              :formatter="handleTableValueFormat"
            />
            <el-table-column prop="status" label="识别状态" width="120">
              <template slot-scope="scope">
                <div
                  :style="{
                    color: handleRecognizeStatusColor(scope.row.status),
                  }"
                >
                  <span>{{
                    handleValueToLabel(
                      "InvoiceRecognizedStatus",
                      scope.row.status
                    )
                  }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="operate" label="操作" width="120">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="handleInvoiceUpdate(scope.$index, scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  @click="handleInvoiceDelete(scope.$index, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 发票编辑弹窗 -->
    <el-dialog
      append-to-body
      title="编辑发票"
      :visible.sync="updateVisible"
      :close-on-click-modal="false"
      width="500px"
      @close="handleInvoiceUpdateCancel"
    >
      <el-form
        ref="invoiceUpdate"
        :model="invoiceUpdate"
        :rules="rules"
        :inline="true"
        label-position="top"
        label-width="120px"
      >
        <el-form-item label="发票类型">
          <el-select
            v-model="invoiceUpdate.invoiceType"
            placeholder="请选择发票类型"
          >
            <el-option
              v-for="item in invoiceTypeSet"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="isSkVatInvoice" label="发票代码" prop="invoiceCode">
          <el-input
            v-model="invoiceUpdate.invoiceCode"
            placeholder="请输入发票代码"
            maxlength="12"
          />
        </el-form-item>
        <el-form-item v-if="isSkVatInvoice" label="发票号码" prop="invoiceNo">
          <el-input
            v-model="invoiceUpdate.invoiceNo"
            placeholder="请输入发票号码"
            maxlength="8"
          />
        </el-form-item>
        <el-form-item label="开票日期" placeholder="请输入开票日期">
          <el-date-picker
            v-model="invoiceUpdate.invoiceTime"
            type="date"
            value-format="yyyy-MM-dd"
            :picker-options="pickerDisabled"
            placeholder="请选择开票日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item v-if="isSdVatInvoice" label="数电票号" prop="eInvoiceNo">
          <el-input
            v-model="invoiceUpdate.eInvoiceNo"
            placeholder="请输入数电票号"
            maxlength="8"
          />
        </el-form-item>
        <el-form-item v-if="sumAmountVisable" label="价税合计" prop="sumAmount">
          <el-input
            v-model="invoiceUpdate.sumAmount"
            placeholder="请输入价税合计"
          />
        </el-form-item>
        <el-form-item v-if="amountVisable" label="未税金额" prop="amount">
          <el-input
            v-model="invoiceUpdate.amount"
            placeholder="请输入未税金额"
          />
        </el-form-item>
        <el-form-item
          v-if="verifyCodeVisable"
          label="校验码后6位"
          prop="verifyCode"
        >
          <el-input
            v-model="invoiceUpdate.verifyCode"
            placeholder="请输入校验码后6位"
            maxlength="6"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="default" @click="handleInvoiceUpdateCancel"
          >取 消</el-button
        >
        <el-button type="primary" @click="handleInvoiceUpdateConfirm"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 发票删除弹窗 -->
    <el-dialog
      append-to-body
      title="删除"
      :visible.sync="deleteVisible"
      width="506px"
      @close="handleInvoiceDeleteCancel"
    >
      <div class="dialog-body">是否删除该张发票？</div>
      <div class="dialog-footer" slot="footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleInvoiceDeleteConfirm"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 提交成功弹窗 -->
    <el-dialog
      append-to-body
      title="提交成功"
      width="480px"
      :visible.sync="submitSuccessVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog-success">
        <div v-if="isShow">
          当前批次中存在识别重复数据，您可在<span
            @click="handleJumpRecognizeFailureRecord"
            >识别失败记录</span
          >页面重新编辑发票信息。
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleSubmitSuccess('continue')"
          >继续扫码收票</el-button
        >
        <el-button type="primary" @click="handleSubmitSuccess('back')"
          >返回收票中心</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus, EventDef } from "@/plugins/event-bus.js";
import {
  checkInvoiceRepeat,
  getRecognizeBatchNo,
  saveScanningGunRecognizeData,
  submitInvoice,
} from "@/service/invoice-center";
import {
  tableStyle,
  pagingMethods,
  dateOption,
  changeMethods,
  commonsMethods,
} from "@/util/mixins";
import { parseInvoiceQrCode } from "@/assets/js/invoice-util";
import RegexLib from "@/assets/js/regex-lib";
import OrganizationSelect from "@/components/widgets/OrganizationSelect";
import { DataUtil } from "@/util/dataUtil";

export default {
  name: "ColSmsp",
  mixins: [
    tableStyle,
    pagingMethods,
    dateOption,
    changeMethods,
    commonsMethods,
  ],
  components: { OrganizationSelect },
  data() {
    return {
      tableData: [],
      dataLoading: false,
      invoiceQrCode: "",
      updateVisible: false,
      invoiceUpdate: {
        invoiceCode: undefined,
        invoiceNo: undefined,
        invoiceType: undefined,
        invoiceTime: undefined,
        amount: undefined,
        sumAmount: undefined,
        verifyCode: undefined,
      },
      source: "CENTER",
      channel: "GUN",
      invoiceLabel: "ordinary",
      currentIndex: -1,
      rules: {
        invoiceCode: [
          { required: true, message: "请输入发票代码", trigger: "blur" },
          {
            min: 10,
            max: 12,
            message: "请输入10位或12位的发票代码",
            trigger: "blur",
          },
          { pattern: RegexLib.InvoiceCode, message: "请输入正确的发票代码" },
        ],
        invoiceNo: [
          { required: true, message: "请输入发票号码", trigger: "blur" },
          { min: 8, max: 8, message: "请输入8位发票号码", trigger: "blur" },
          { pattern: RegexLib.InvoiceNo, message: "请输入正确的发票号码" },
        ],
        eInvoiceNo: [
          { required: true, message: "请输入数电票号", trigger: "blur" },
          { min: 20, max: 20, message: "请输入20位数电票号", trigger: "blur" },
          { pattern: RegexLib.EInvoiceNo, message: "请输入正确的数电票号" },
        ],
        amount: [
          { required: true, message: "请输入未税金额", trigger: "blur" },
          {
            pattern: RegexLib.Amount,
            message: "请输入正确的未税金额",
          },
        ],
        sumAmount: [
          { required: true, message: "请输入价税合计", trigger: "blur" },
          {
            pattern: RegexLib.Amount,
            message: "请输入正确的价税合计",
          },
        ],
        checkCode: [
          { required: true, message: "请输入校验码", trigger: "blur" },
          { min: 6, max: 6, message: "请输入校验码后六位", trigger: "blur" },
          {
            pattern: RegexLib.InvoiceVerifyCode,
            message: "请输入正确的校验码",
          },
        ],
      },
      pickerDisabled: {
        disabledDate(date) {
          return date.getTime() > Date.now();
        },
      },
      deleteVisible: false,
      invoiceDeleteData: {},
      submitSuccessVisible: false,
      recognizeLeaveType: "",
      batchNo: "",
      isShow: false,
      org: {
        orgId: undefined,
      },
    };
  },
  //01,32,,24112000000054031604,413.00,20240604,,DAD1
  computed: {
    isDisabled() {
      return this.tableData.length <= 0;
    },
    verifyCodeVisable() {
      return (
        [
          "EleInvoice",
          "VolInvoice",
          "GenerInvoice",
          "EleTollInvoice",
          "UsedCarInvoice",
          "EleUsedCarInvoice",
          "PaPerUsedCarInvoice",
          "PaPerVehInvoice",
          "FullElePaperSpecInvoice",
          "FullElePaperGenerInvoice",
        ].indexOf(this.invoiceUpdate.invoiceType) != -1
      );
    },
    amountVisable() {
      return (
        [
          "SpecInvoice",
          "EleSpecInvoice",
          "VehInvoice",
          "EVehInvoice",
          "FullElePaperSpecInvoice",
          "FullElePaperGenerInvoice",
        ].indexOf(this.invoiceUpdate.invoiceType) != -1
      );
    },
    sumAmountVisable() {
      return (
        [
          "FullEleSpecInvoice",
          "FullEleGenerInvoice",
          "FullElePaperSpecInvoice",
          "FullElePaperGenerInvoice",
          "ElePlaneInvoice",
          "EleTrainInvoice",
        ].indexOf(this.invoiceUpdate.invoiceType) != -1
      );
    },
    isSkVatInvoice() {
      return (
        [
          "EleInvoice",
          "VolInvoice",
          "VehInvoice",
          "EVehInvoice",
          "SpecInvoice",
          "GenerInvoice",
          "EleTollInvoice",
          "EleSpecInvoice",
          "UsedCarInvoice",
          "TransportInvoice",
          "EleUsedCarInvoice",
          "FullElePaperSpecInvoice",
          "FullElePaperGenerInvoice",
        ].indexOf(this.invoiceUpdate.invoiceType) != -1
      );
    },
    isSdVatInvoice() {
      return (
        [
          "ElePlaneInvoice",
          "EleTrainInvoice",
          "FullEleSpecInvoice",
          "FullEleGenerInvoice",
        ].indexOf(this.invoiceUpdate.invoiceType) != -1
      );
    },
  },
  created() {
    this.invoiceTypeSet = this.$store.state.enums.InvoiceType;
    this.org.orgId = this.$store.state.user.currUser.orgId;
  },
  mounted() {
    this.$refs.textarea.focus();
  },
  methods: {
    /* 表格值格式化 */
    handleTableValueFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case "发票类型":
          return this.handleValueToLabel("InvoiceType", value);
        default:
          return value;
      }
    },
    /* 解析二维码 */
    handleAnalysisQrCode() {
      if (this.invoiceQrCode === "") return;
      if (!this.org.orgId) {
        this.toast("请选择收票组织！", "warning");
        return;
      }

      let content = this.invoiceQrCode.replace("/，/g", ",");
      const res = parseInvoiceQrCode(content);
      console.log("解析发票二维码的结果：", res);

      const { success, invoice } = res;

      if (success) {
        // 校验发票数据
        const validRes = this.validateInvoiceProperties(invoice);
        if (!validRes) return;

        // 判断是否存在
        const invoiceCode = this.tableData.findIndex(
          (item) => item.invoiceCode === invoice.invoiceCode
        );
        const eInvoiceNo = this.tableData.findIndex(
          (item) => item.eInvoiceNo === invoice.eInvoiceNo
        );
        const invoiceNo = this.tableData.findIndex(
          (item) => item.invoiceNo === invoice.invoiceNo
        );

        if (invoiceCode != -1 && invoiceNo != -1 && eInvoiceNo != -1) {
          this.toast("发票重复，列表中已存在！", "warning");
          return;
        }

        this.dataLoading = true;
        getRecognizeBatchNo().then((res) => {
          if (res.success) {
            this.batchNo = res.data;

            // 添加扫描数据
            invoice.batchNo = this.batchNo;
            invoice.batchIndex = 1;
            invoice.source = this.source;
            invoice.channel = this.channel;
            invoice.invoiceLabel = this.invoiceLabel;
            invoice.orgId = this.org.orgId;
            // 发票重复校验
            // console.log(invoice, 'invoice');
            checkInvoiceRepeat(invoice).then((result) => {
              if (result.success) {
                invoice.status = result.data.status;
                this.tableData.push(invoice);
                this.invoiceQrCode = "";
                this.$refs.textarea.focus();
              }
              this.dataLoading = false;
            });
          } else {
            this.dataLoading = false;
          }
        });
      }
    },
    /* 发票提交 */
    async handleInvoiceSubmit() {
      const loading = this.handleLoading();

      // 扫码枪数据保存
      const invoices = this.tableData;
      invoices.forEach((invoice, index) => {
        invoice.batchNo = this.batchNo;
        invoice.batchIndex = index + 1;
      });
      const saveScanResult = await saveScanningGunRecognizeData(invoices);
      if (!saveScanResult.success) {
        this.toast("数据提交失败！", "error");
        loading.close();
        return;
      }

      // 提交识别信息
      let param = {
        ids: saveScanResult.data,
        source: "CENTER",
        channel: "GUN",
        invoiceLabel: "ordinary",
        orgId: this.org.orgId,
      };
      const submitResult = await submitInvoice(param);
      if (!submitResult.success) {
        this.toast("数据提交失败！", "error");
        loading.close();
      }
      const statues = [...new Set(this.tableData.map((i) => i.status))];
      if (statues.includes("REPEAT")) {
        this.isShow = true;
      }
      this.invoiceQrCode = "";
      this.$refs.textarea.focus();
      this.tableData = [];
      this.submitSuccessVisible = true;
      EventBus.$emit(EventDef.COL_INVOICE_LIST_REFRESH);
      loading.close();
    },
    /* 提交前检查 */
    validateInvoiceProperties(invoice) {
      if (
        [
          "GenerInvoice",
          "SpecInvoice",
          "EleInvoice",
          "EleSpecInvoice",
          "FullElePaperGenerInvoice",
          "FullElePaperSpecInvoice",
          "FullEleSpecInvoice",
          "FullEleGenerInvoice",
        ].indexOf(invoice.invoiceType) == -1
      ) {
        this.toast("识别信息有误，发票类型有误！", "warning");
        return false;
      }
      // 税控发票
      if (
        [
          "GenerInvoice",
          "SpecInvoice",
          "EleInvoice",
          "EleSpecInvoice",
          "FullElePaperGenerInvoice",
          "FullElePaperSpecInvoice",
        ].indexOf(invoice.invoiceType) != -1
      ) {
        if (!RegexLib.InvoiceCode.test(invoice.invoiceCode)) {
          this.toast("识别信息有误，发票代码有误！", "warning");
          return false;
        }
        if (!RegexLib.InvoiceNo.test(invoice.invoiceNo)) {
          this.toast("识别信息有误，发票号码有误！", "warning");
          return false;
        }
        if (!RegexLib.Amount.test(invoice.amount)) {
          this.toast("识别信息有误，未税金额有误！", "warning");
          return false;
        }
      }

      // 数电发票
      if (
        ["FullEleSpecInvoice", "FullEleGenerInvoice"].indexOf(
          invoice.invoiceType
        ) != -1
      ) {
        if (!RegexLib.EInvoiceNo.test(invoice.eInvoiceNo)) {
          this.toast("识别信息有误，数电票号有误！", "warning");
          return false;
        }
        if (!RegexLib.Amount.test(invoice.sumAmount)) {
          this.toast("识别信息有误，价税合计有误！", "warning");
          return false;
        }
      }

      if (
        ["GenerInvoice", "EleInvoice", "FullElePaperGenerInvoice"].indexOf(
          invoice.invoiceType
        ) != -1
      ) {
        if (!RegexLib.InvoiceVerifyCode.test(invoice.verifyCode)) {
          this.toast("识别信息有误，校验码值有误！", "warning");
          return false;
        }
      }

      const date = invoice.invoiceTime;
      if (!(new Date(date).getDate() == date.substring(date.length - 2))) {
        this.toast("识别信息有误，开票日期有误！", "warning");
        return false;
      }
      return true;
    },
    /* 跳转识别失败记录 */
    handleJumpRecognizeFailureRecord() {
      // 跳转页面
      this.$router.replace({
        path: "/collect/recognize/failure/record",
        query: {
          batchNo: this.batchNo,
          status: this.recognizeLeaveType == "F" ? "FAILURE" : "REPEAT",
        },
      });
    },
    /* 提交成功 */
    handleSubmitSuccess(type) {
      this.submitSuccessVisible = false;
      switch (type) {
        case "continue":
          this.tableData = [];
          this.currentIndex = -1;
          this.batchNo = "";
          this.invoiceQrCode = "";
          break;
        case "back":
        default:
          this.$router.replace({
            path: "/collect/list",
            query: { refresh: "Y" },
          });
          break;
      }
    },
    /* 发票编辑弹窗 */
    handleInvoiceUpdate(index, invoice) {
      this.currentIndex = index;
      this.invoiceUpdate = DataUtil.clone(invoice);
      this.updateVisible = true;
    },
    /* 发票编辑取消 */
    handleInvoiceUpdateCancel() {
      this.currentIndex = -1;
      this.invoiceUpdate = {};
      this.updateVisible = false;
    },
    /* 发票编辑确定 */
    handleInvoiceUpdateConfirm() {
      let invoiceCurrent = this.tableData[this.currentIndex];
      Object.assign(invoiceCurrent, this.invoiceUpdate);
      // console.log("invoiceUpdate", JSON.stringify(this.invoiceUpdate));
      // console.log("invoiceCurrent：", JSON.stringify(invoiceCurrent));
      this.currentIndex = -1;
      this.updateVisible = false;
    },
    /* 发票删除弹窗 */
    handleInvoiceDelete(index, invoice) {
      this.currentIndex = index;
      this.invoiceDeleteData = invoice;
      this.deleteVisible = true;
    },
    /* 发票删除取消 */
    handleInvoiceDeleteCancel() {
      this.currentIndex = -1;
      this.invoiceDeleteData = {};
      this.deleteVisible = false;
    },
    /* 发票识别状态 */
    handleRecognizeStatusColor(status) {
      switch (status) {
        case "FAILURE":
          return "#f5222d";
        case "REPEAT":
          return "#FF7A08";
        default:
          return "#666666";
      }
    },
    /* 发票删除确定 */
    handleInvoiceDeleteConfirm() {
      if (this.currentIndex != -1) {
        this.tableData.splice(this.currentIndex, 1);
        this.currentIndex = -1;
      }
      this.deleteVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-select {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 22px;

  .select-title {
    display: flex;
    padding: 20px;
  }
}

.content-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100% - 180px);

  .content-table {
    .table-title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: 22px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }

    .table-content {
      .el-button {
        padding: 0;
      }
    }
  }
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.dialog-success {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;

  span {
    color: #1890ff;
  }
}

::v-deep .el-dialog__header {
  padding: 24px 24px 0;
}

::v-deep .el-dialog__body {
  padding: 24px;
  text-align: left;
}

::v-deep .el-dialog__footer {
  padding: 0 24px 24px;
}

::v-deep .el-form--inline .el-form-item {
  margin: 5px 10px;
}
</style>
